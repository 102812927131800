import { fetchApi } from "../helpers/fetchApi";

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
const HOST_URL = process.env.REACT_APP_HOST_URL;

export const fetchUserAD = async (userPrincipalName, displayName) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetUsuarioAD?userName=${encodeURIComponent(displayName)}&userMail=${userPrincipalName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchConfiguracion = async () => {
  const [payload, error] = await fetchApi(SERVICE_URL + 'webservices/SCAAppDataWebService_Web.asmx/GetConfiguraciones', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchBarreras = async (barreras) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetBarreras?idDevices=${barreras}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchContratistas = async (idGarminGroup) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetContratistas?idGarminGroup=${idGarminGroup}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchAutoridades = async (idGarminGroup) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetSupervisores?idGarminGroup=${idGarminGroup}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchPermisosDni = async () => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetPermisosDni`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchPermisosPatente = async () => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetPermisosPatente`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    }
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchMovimientosTotem = async (fechaDesde, fechaHasta, dni, totems) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetMovimientosTotem?fechaMovimiento=${fechaDesde}&fechaMovimientoHasta=${fechaHasta}&dni=${dni}&totems=${totems}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchMovimientosVehiculoTotem = async (fechaDesde, fechaHasta, license, totems, idObra, idUsuario) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetMovimientosVehiculoTotem?fechaMovimiento=${fechaDesde}&fechaMovimientoHasta=${fechaHasta}&license=${license}&totems=${totems}&idZone=${idObra}&idUser=${idUsuario}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchSetBarrera = async (idDevice, comando, idUsuario) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetBarrera?idDevice=${idDevice}&comandoBarrera=${comando}&idUsuario=${idUsuario}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchGetUsuario = async (user, pass) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetUsuario?usuarioLogin=${user}&passwordLogin=${pass}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchGetDriver = async (dni) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetDriver?dniPersonal=${dni}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchGetVehicle = async (licensePlate) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetVehicle?licensePlate=${licensePlate}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchGetPersonalPermisoTratadoDni = async (dni) => {
  return await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetPersonalPermisoTratadoDni?dni=${dni}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': HOST_URL,
      },
    });
}

export const fetchGetVehiculoPermisoTratadoPatente = async (licensePlate) => {
  return await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetVehiculoPermisoTratadoPatente?dni=${licensePlate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': HOST_URL,
      },
    });
}

export const fetchGetMotivosAutorizacion = async () => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetMotivosAutorizacion`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload;
  }
  return error;
}

export const fetchSetVisitaOk = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetVisitaOk?dniPersonal=${datosFetch.dniPersonal}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetVisitaVehicleOk = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetVisitaVehicleOk?licensePlate=${datosFetch.licensePlate}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryPeatonal = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryPeatonal?fechaMovimiento=${datosFetch.fechaMovimiento}&idPersonal=${datosFetch.idPersonal}&dniPersonal=${datosFetch.dniPersonal}&nombrePersonal=${datosFetch.nombrePersonal}&tipoMovimiento=${datosFetch.tipoMovimiento}&TipoPeatonal=${datosFetch.TipoPeatonal}&idCustomer=${datosFetch.idCustomer}&idJefeSupervisor=${datosFetch.idJefeSupervisor}&obsPeatonal=${datosFetch.obsPeatonal}&accesoNombre=${datosFetch.accesoNombre}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&idUser=${datosFetch.idUser}&tipoEntrada=${datosFetch.tipoEntrada}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryPeatonalVehicle = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryPeatonalVehicle?fechaMovimiento=${datosFetch.fechaMovimiento}&idVehicle=${datosFetch.idVehicle}&licensePlate=${datosFetch.licensePlate}&nombreVehiculo=${datosFetch.nombreVehiculo}&tipoMovimiento=${datosFetch.tipoMovimiento}&TipoPeatonal=${datosFetch.TipoPeatonal}&idCustomer=${datosFetch.idCustomer}&idJefeSupervisor=${datosFetch.idJefeSupervisor}&obsPeatonal=${datosFetch.obsPeatonal}&accesoNombre=${datosFetch.accesoNombre}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&idUser=${datosFetch.idUser}&tipoEntrada=${datosFetch.tipoEntrada}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryAuthorizationAuthorized = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryAuthorizationAuthorized?fechaPedido=${datosFetch.fechaMovimiento}&idPersonal=${datosFetch.idPersonal}&dniPersonal=${datosFetch.dniPersonal}&nombrePersonal=${datosFetch.nombrePersonal}&idCliente=${datosFetch.idCustomer}&idSupervisor=0&motivoPedido=${datosFetch.descripcionMotivo}&obsPedido=${datosFetch.obsPeatonal}&idUser=${datosFetch.idUser}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&namePuesto=${datosFetch.accesoNombre}&codigoMotivoAutorizacion=${datosFetch.idMotivo}&observacionesAutorizacion=${datosFetch.obsMotivo}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryAuthorizationAuthorizedVehicle = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryAuthorizationAuthorizedVehicle?fechaPedido=${datosFetch.fechaMovimiento}&idVehicle=${datosFetch.idVehicle}&licensePlate=${datosFetch.licensePlate}&nombreVehiculo=${datosFetch.nombreVehiculo}&idCliente=${datosFetch.idCustomer}&idSupervisor=0&motivoPedido=${datosFetch.descripcionMotivo}&obsPedido=${datosFetch.obsPeatonal}&idUser=${datosFetch.idUser}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&namePuesto=${datosFetch.accesoNombre}&codigoMotivoAutorizacion=${datosFetch.idMotivo}&observacionesAutorizacion=${datosFetch.obsMotivo}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryPeatonalAuditor = async (datosFetch) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryPeatonalAuditor?fechaMovimiento=${datosFetch.fechaMovimiento}&idPersonal=${datosFetch.idPersonal}&dniPersonal=${datosFetch.dniPersonal}&nombrePersonal=${datosFetch.nombrePersonal}&tipoMovimiento=${datosFetch.tipoMovimiento}&TipoPeatonal=${datosFetch.TipoPeatonal}&idCustomer=${datosFetch.idCustomer}&idJefesSupervisor=${datosFetch.idJefesSupervisor}&obsPeatonal=${datosFetch.obsPeatonal}&accesoNombre=${datosFetch.accesoNombre}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&idUser=${datosFetch.idUser}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryAuthorizationPeatonal = async (datosFetch, autoridad) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryAuthorizationPeatonal?fechaPedido=${datosFetch.fechaMovimiento}&dniPersonal=${datosFetch.dniPersonal}&namePersonal=${datosFetch.nombrePersonal}&TipoPeatonal=${datosFetch.TipoPeatonal}&idCliente=${datosFetch.idCustomer}&idSupervisor=${autoridad}&obsPedido=${datosFetch.obsPeatonal}&namePuesto=${datosFetch.accesoNombre}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&idUser=${datosFetch.idUser}&motivoPedido=${datosFetch.notaMotivo}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}

export const fetchSetEntryAuthorizationPeatonalVehicle = async (datosFetch, autoridad) => {
  const [payload, error] = await fetchApi(SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/SetEntryAuthorizationPeatonalVehicle?fechaPedido=${datosFetch.fechaMovimiento}&licensePlate=${datosFetch.licensePlate}&nombreVehiculo=${datosFetch.nombreVehiculo}&TipoPeatonal=${datosFetch.TipoPeatonal}&idCliente=${datosFetch.idCustomer}&idSupervisor=${autoridad}&obsPedido=${datosFetch.obsPeatonal}&namePuesto=${datosFetch.accesoNombre}&latPosicion=${datosFetch.latPosicion}&lonPosicion=${datosFetch.lonPosicion}&idUser=${datosFetch.idUser}&motivoPedido=${datosFetch.notaMotivo}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Host': HOST_URL,
    },
  });
  if(!error){
    return payload
  }
  return error;
}