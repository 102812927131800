import { memo } from 'react';
import { ListItem,
ListItemIcon,
ListItemText,
Tooltip } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import VehicleAddIcon from '@mui/icons-material/AddCircleOutline';
import VehicleRemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';


const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  })
);

export const MainOperatorItems = () => {
  const classes = useStyles();
  return (
    <>
      <Link to="/ingresoPeatonal" className={classes.link}>
        <Tooltip title='Ingreso Peatonal' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText primary="Ingreso Peatonal" />
          </ListItem>
        </Tooltip>
      </Link>

      <Link to='/salidaPeatonal' className={classes.link}>
        <Tooltip title='Salida Peatonal' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <PersonRemoveAlt1Icon />
            </ListItemIcon>
            <ListItemText primary="Salida Peatonal" />
          </ListItem>
        </Tooltip>
      </Link>

      {/* <Link to="/ingresoVehicular" className={classes.link}>
        <Tooltip title='Ingreso Vehicular' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <VehicleAddIcon />
            </ListItemIcon>
            <ListItemText primary="Ingreso Vehicular" />
          </ListItem>
        </Tooltip>
      </Link>

      <Link to='/salidaVehicular' className={classes.link}>
        <Tooltip title='Salida Vehicular' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <VehicleRemoveIcon />
            </ListItemIcon>
            <ListItemText primary="Salida Vehicular" />
          </ListItem>
        </Tooltip>
      </Link> */}
    </>
  );
};

export default memo(MainOperatorItems);
