import { 
  BrowserRouter,
  Routes,
  Route } from 'react-router-dom';
import Home from './pages/homeGrid';
import IngresoPeatonal from './pages/ingresoPeatonal';
import IngresoVehicular from './pages/ingresoVehicular';
import SalidaPeatonal from './pages/salidaPeatonal';
import SalidaVehicular from './pages/salidaVehicular';
import Login from './pages/login';
import LoginAd from './pages/loginad';
import Barreras from './pages/barreras';
import MovimientoVehiculoTotemsEntrada from './pages/movimientoVehiculoTotemsEntrada';
import MovimientoTotemsEntrada from './pages/movimientoTotemsEntrada';
import MovimientoTotemsSalida from './pages/movimientoTotemsSalida';
import AccesosPeatonales from './pages/accesosPeatonales';
import PermisoDeIngresoPendiente from './pages/permisoDeIngresoPendientes';
import PermisoDeIngresoTratados from './pages/permisoDeIngresoTratados';
import PermisoDeIngresoVehicularPendiente from './pages/permisoDeIngresoVehicularPendientes';
import PermisoDeIngresoVehicularTratados from './pages/permisoDeIngresoVehicularTratados';
import VehiculosEnCamino from './pages/vehiculosEnCamino';
import DetalleDeVehiculo from './pages/detalleDeVehiculo';
import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' &&
        process.env.REACT_IS_HTTPS === '1' && 
        window.location.protocol === 'http:') {
      window.location.href = `https://${window.location.host}`;
    }
  });
  
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/loginad' element={<LoginAd />} />

          <Route path='/ingresoPeatonal' element={<IngresoPeatonal />} />
          <Route path='/salidaPeatonal' element={<SalidaPeatonal />} />
          <Route path='/permisoIngresoPendiente' element={<PermisoDeIngresoPendiente />} />
          <Route path='/permisoDeIngresoTratados' element={<PermisoDeIngresoTratados />} />
          <Route path='/accesosPeatonales' element={<AccesosPeatonales />} />
          <Route path='/movimientoTotemsEntrada' element={<MovimientoTotemsEntrada />} />
          <Route path='/movimientoTotemsSalida' element={<MovimientoTotemsSalida />} />

          <Route path='/vehiculosEnCamino' element={<VehiculosEnCamino />} />
          <Route path='/detalleDeVehiculo' element={<DetalleDeVehiculo />} />
          <Route path='/movimientoVehiculoTotemsEntrada' element={<MovimientoVehiculoTotemsEntrada />} />
          <Route path='/ingresoVehicular' element={<IngresoVehicular />} />
          <Route path='/salidaVehicular' element={<SalidaVehicular />} />
          <Route path='/permisoIngresoVehicularPendiente' element={<PermisoDeIngresoVehicularPendiente />} />
          <Route path='/permisoDeIngresoVehicularTratados' element={<PermisoDeIngresoVehicularTratados />} />

          <Route path='/barreras' element={<Barreras />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;