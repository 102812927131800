import * as React from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../store';
import { setListadoObras, setObra } from '../reducers/selectorObra';
import { FC } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { MainListItems, SecondaryListItems, TertiaryListItems, QuaternaryListItems, SesionListItems } from './list-items';
import { makeStyles } from '@mui/styles';
import { 
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Footer from './footer'
import HomeIcon from '@mui/icons-material/Home';
import { fetchConfiguracion } from '../reducers/fetchWebService';
import CachedIcon from '@mui/icons-material/Cached';

const drawerWidth = 340;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles((theme: Theme) => ({
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      height: '90vh'
    },
}));
  
const Dashboard: FC<{ title: string, refresco: any }> = ({ title = '', refresco, children }) => {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const { listadoObras } = useSelector((state: RootState) => state.selectorObra);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const [obraSeleccionada, setObraSeleccionada] = React.useState('0');

  const handleObraChange = (event: any) => {
    setObraSeleccionada(event.target.value);

    setCookie(`datosObra`, event.target.value, {maxAge: 99999999});

    dispatch(setObra(listadoObras[event.target.value]));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const traerObras = async () => {
      let obraIndexSeleccionada = obraSeleccionada;
      let listado = await fetchConfiguracion(); 

      if (cookies.datosObra) {
        setObraSeleccionada(cookies.datosObra);

        obraIndexSeleccionada = cookies.datosObra;
      } 

      if(listadoObras.length < 2){
        dispatch(setListadoObras(listado));
      }
      
      if(obra.Id_Zone === 0){
        dispatch(setObra(listado[obraIndexSeleccionada]));
      }
      
      if(obra.Id_Zone.toString() !== obraIndexSeleccionada && obra.Name != ""){
        setObraSeleccionada(listadoObras.indexOf(obra).toString());
      }
    }

    traerObras();
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
    <AppBar position="fixed" open={open} color='primary' >
      <Toolbar>
        <IconButton
          color="secondary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" color='secondary'>
          {title} - {obra.Name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {
          refresco && 
          <Box>
            <Button onClick={() => refresco()}><CachedIcon color='secondary'/> </Button>
          </Box>
        }
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={obraSeleccionada}
            onChange={handleObraChange}
            fullWidth
            style={{ backgroundColor: 'white', maxWidth: 150 }}
            size="small"
          >
            {
              listadoObras.map((obraItem, index) => (
                <MenuItem key={obraItem.Id_Zone} value={index}>{obraItem.Name}</MenuItem>
              ))
            }
          </Select>
        </Box>
        <Box sx={{ display: { xs: 'flex' } }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={() => navigate('/')}
            color="secondary"
          >
            <HomeIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ArrowBackIosIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <MainListItems />
      </List>
      <Divider />
      <List>
        <SecondaryListItems />
      </List>
      <Divider />
      <List >
        <TertiaryListItems />
      </List>
      <Divider />
      <List >
        <QuaternaryListItems />
      </List>
      <Divider />
      <List>
       <SesionListItems />
      </List>
    </Drawer>
    <Box component="main" sx={{ flexGrow: 1, p: 3, width: '93vw', maxHeight: '100vh' }}>
      <DrawerHeader />
      <Container maxWidth={false} className={classes.container}>
        {children}
        <Box pt={2}>
          <Footer />
        </Box>
      </Container>
    </Box>
  </Box>
  );
};

export default Dashboard;