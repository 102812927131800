import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider, Grid, Stack, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { makeStyles, createStyles } from '@mui/styles';
import Dashboard from '../components/dashboard';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { fetchGetVehicle, fetchGetVehiculoPermisoTratadoPatente, fetchSetEntryPeatonalVehicle } from '../reducers/fetchWebService';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() =>
  createStyles({
    boxHeight: {
      height: '1em',
    },
    gridContainer: {
      marginBottom: 5,
    },
    estado: {
      padding: '0.7rem',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.38)',
      borderRadius: 4,
    }
  })
);

enum Estados {
  LIMPIO = '',
  APTO = 'APTO',
  NO_APTO = 'NO APTO',
  NO_APTO_PERMISO = 'NO APTO CON PERMISO',
  NO_CARGADO = 'NO CARGADO',
}

enum Tipos {
  PERSONAL = 'VEHICULO',
  INVITADO = 'INVITADO',
  PENDIENTE = 'PENDIENTE DE CARGA',
}

const estadoInicialForm = {
  licensePlate: '',
  nombre: '',
  idVehicle: '',
  estado: Estados.LIMPIO,
  tipo: '',
};

const SalidaVehicular = () => {
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const classes = useStyles();
  const [cookies] = useCookies();
  const [licensePlate, setLicensePlate] = useState(estadoInicialForm.licensePlate);
  const [idVehicle, setIdVehicle] = useState(estadoInicialForm.idVehicle);
  const [nombre, setNombre] = useState(estadoInicialForm.nombre);
  const [estado, setEstado] = useState(estadoInicialForm.estado);
  const [tipo, setTipo] = useState(estadoInicialForm.tipo);
  const fecha = new Date().toLocaleDateString();
  const navigate = useNavigate();

  const datosFetch = {
    fechaMovimiento: fecha + ' ' + new Date().toTimeString().slice(0, 8),
    idVehicle: idVehicle,
    licensePlate: licensePlate,
    nombreVehiculo: nombre,
    tipoMovimiento: 'S',
    TipoPeatonal: 1,
    idCustomer: 0,
    idJefeSupervisor: 0,
    obsPeatonal: '',
    accesoNombre: obra.Name,
    latPosicion: obra.Lat,
    lonPosicion: obra.Lon,
    idUser: cookies.Id_User,
    tipoEntrada: '',
  }

  const handleChange = (event) => {
    if(event.target.name === 'licensePlate'){
      setLicensePlate(event.target.value);
    }
    if(event.target.name === 'nombre'){
      setNombre(event.target.value);
    }
  };

  const setDatosVehiculo = async () => {
    let resp =  await fetchGetVehicle(licensePlate);
    
    console.log(resp);

    if(resp.Id_Vehicle !== null && resp.Id_Vehicle !== ""){
      setIdVehicle(resp.Id_Vehicle);
      setNombre(resp.Description);
      setTipo(Tipos.PERSONAL);
      if(resp.Vigencia !== 2){
        setEstado(Estados.APTO);
      }else{
        setEstado(Estados.NO_APTO);
        let respGetVehiculoPermisoTratadoPatente = [];
        const [payload, error] = await fetchGetVehiculoPermisoTratadoPatente(licensePlate);
        if(!error){
          respGetVehiculoPermisoTratadoPatente = payload as any[];
        }
        if(respGetVehiculoPermisoTratadoPatente.length > 0){
          respGetVehiculoPermisoTratadoPatente.forEach(permiso => {
            if(permiso.Estado === 'APROBADO'){
              setEstado(Estados.NO_APTO_PERMISO);
              return;
            }
          });
        }
      }
    }else{
      setIdVehicle(estadoInicialForm.idVehicle);
      setNombre(estadoInicialForm.nombre);
      setEstado(Estados.NO_CARGADO);
      setTipo(Tipos.INVITADO);
    }
  }

  const handlePermisosPatente = () => {
    setDatosVehiculo();
  }

  const limpiarFormulario = () => {
    setLicensePlate(estadoInicialForm.licensePlate);
    setIdVehicle(estadoInicialForm.idVehicle)
    setNombre(estadoInicialForm.nombre);
    setEstado(estadoInicialForm.estado);
    setTipo(estadoInicialForm.tipo);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(estado === Estados.NO_CARGADO){
      if(nombre == ''){
        alert('Ingresar nombre completo');
      }else{
        await fetchSetEntryPeatonalVehicle(datosFetch);
        alert('Salida con éxito')
      }
    }else{
      await fetchSetEntryPeatonalVehicle(datosFetch);
      alert('Salida con éxito')
    }
  }

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  return (
    <Dashboard title='Salida Vehicular' refresco={null}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <form action="" onSubmit={(e) => handleSubmit(e)} style={{ minWidth: '80%' }}>
          <Box>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Obra
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  value={obra.Id_Zone.toString()}
                  fullWidth
                  disabled
                >
                  <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Ingrese la Patente
                </Typography>
              </Grid>
              <Grid item xs={10}>
              <TextField onChange={(e) => handleChange(e)} onKeyDown={(e) => handleChange(e)} name='licensePlate' variant="outlined" size="small" />
              <Button onClick={handlePermisosPatente} variant="contained" size="small"><ManageSearchIcon /></Button>
              </Grid>
            </Grid>
          </Box>

          <Paper variant="outlined" style={{padding: 10}}>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Patente
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField disabled fullWidth value={licensePlate} style={{backgroundColor: 'rgba(224,224,224,1)'}} variant="outlined" size="small"/> 
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Descripci&oacute;n
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField fullWidth disabled={estado === Estados.NO_APTO || estado === Estados.NO_CARGADO ? false : true} onChange={(e) => handleChange(e)} value={nombre} style={estado === Estados.NO_CARGADO ? {} : {backgroundColor: 'rgba(224,224,224,1)'}} name='nombre' variant="outlined" size="small"/>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Estado
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box 
                  className={classes.estado}
                  style={estado === Estados.APTO || estado === Estados.NO_APTO_PERMISO ? {backgroundColor: 'green'} : (estado === Estados.NO_APTO || estado === Estados.NO_CARGADO ? {backgroundColor: 'red'} : {minHeight: '2.5rem'})}>
                  <Typography color='white'>{estado}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Tipo
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField disabled fullWidth value={tipo} style={{backgroundColor: 'rgba(224,224,224,1)'}} name='tipo' variant="outlined" size="small"/> 
              </Grid>
            </Grid>
          </Paper>

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent= 'center'
            spacing={2}
            style={{ marginTop: 15}}
          >
            <Button type='submit' variant="outlined" size="large" color="success" startIcon={<CheckIcon />}>Salida</Button>
            <Button variant="outlined" size="large" color="error" startIcon={<CancelIcon />}>Cancelar</Button>
            <Button onClick={() => limpiarFormulario()} variant="outlined" size="large" color="info" startIcon={<AutorenewIcon />}>Limpiar</Button>
          </Stack>
        </form>
      </div>
    </Dashboard>
  );
}

export default SalidaVehicular;