import { Grid, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DatePicker from '@mui/lab/DatePicker';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchMovimientosTotem } from "../reducers/fetchWebService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IngresoPeatonal from "../components/ingresoPeatonal";
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { makeStyles, createStyles } from '@mui/styles';
import { es } from "date-fns/locale";

const style = {
  position: 'relative' as 'relative',
  top: '50%',
  left: '50%',
  bottom: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '1rem',
  overflow: 'auto'
};

const useStyles = makeStyles(() => 
  createStyles({
    stickyHeader: {
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
      zIndex: 999,
    },
  }),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '5px 10px',
}

const estiloAmarillo = {
  color: "white",
  backgroundColor: "#FFA600",
  padding: '5px 10px',
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '5px 10px',
}

const MovimientoTotemsEntrada = () => {
  const classes = useStyles();
  const lengthPrevia = useRef(0);
  const navigate = useNavigate();
  const { obra } = useSelector((state: RootState) => state.selectorObra)
  const [cargando, setCargando] = useState(true);
  const [movimientos, setMovimientos] = useState<any>([]);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const [cookies] = useCookies();
  const hoy = new Date();
  const [hoyString, setHoyString] = useState<string>();
  const [fechaMovimientoDesde, setFechaMovimientoDesde] = React.useState<Date | null>(hoy);
  const [fechaMovimientoHasta, setFechaMovimientoHasta] = React.useState<Date | null>(hoy);
  const [fechaMovimientoDesdeString, setFechaMovimientoDesdeString] = useState<string>(hoyString);
  const [fechaMovimientoHastaString, setFechaMovimientoHastaString] = useState<string>(hoyString);
  const [dniBusqueda, setDniBusqueda] = useState<string>('');
  const [refrescarBusqueda, setRefrescarBusqueda] = useState<boolean>(true);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const radioSelectIndex = useRef(-1);
  
  const [dniPersonaParaPermiso, setDniPersonaParaPermiso] = useState<any>();
  const [openModalIngreso, setOpenModalIngreso] = useState(false);
  const handleOpenFormIngreso = () => setOpenModalIngreso(true);
  const handleCloseFormIngreso = () => setOpenModalIngreso(false);
  
  //los totems pueden ir separados por coma, ej: '28,29,30' 
  const [totemsTraidos, setTotemsTraidos] = useState('');

  const setearTotemsSegunObra = () => {
    let totemsEntrada = obra.Totems.filter((totem) => totem.Tipo === "E");
    let codigosTotems = [];
    totemsEntrada.map(totem => (
      codigosTotems.push(totem.Id_Totem)
    ));
    setTotemsTraidos(codigosTotems.join());
    return codigosTotems.join();
  };

  const [orderColumn, setOrderColumn]= useState("Hora");
  const [orderDirection, setOrderDirection]= useState("desc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Hora") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(movimientos, columnName, columnOrder);

    setMovimientos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async (diff?) => {
    let totemsTemP = setearTotemsSegunObra();
    
    fechaHoyString();

    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    let payload = await fetchMovimientosTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, dniBusqueda, totemsTemP);
    lengthPrevia.current = payload.length;
    setCargando(true);
    setMovimientos([]);
    setMovimientos(payload);
    if(diff) {
      let rowNuevo = radioSelectIndex.current+diff
      radioSelectIndex.current = rowNuevo;
    }
    setCargando(false);
    setUltimoRefresco(new Date().toTimeString());
    if (rowSelectIndex != undefined) {
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  // const verificarNuevosDatos = async () => {
  //   let totemsTemP = setearTotemsSegunObra();
  //   let payload = await fetchMovimientosTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, dniBusqueda, totemsTemP);
  //   if(payload.length !== lengthPrevia.current){
  //     let diff = payload.length - lengthPrevia.current;
  //     leer(diff);
  //   }
  // }
  
  useEffect(() => {
    leer();

    // const interval = setInterval(() => {
    //   verificarNuevosDatos();
    // }, 30000); // 30 seg
    // return () => clearInterval(interval);
  }, [refrescarBusqueda, obra]);

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  const fechaHoyString = () => {
    let diaHoy = hoy.getDate();
    let mesHoy = hoy.getMonth();
    let yearHoy = hoy.getFullYear();

    if (mesHoy<10 && diaHoy<10){
      setHoyString(`0${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy>=10 && diaHoy<10){
      setHoyString(`0${diaHoy}/${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy<10 && diaHoy>=10){
      setHoyString(`${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else{
      setHoyString(`${diaHoy}/${mesHoy}/${yearHoy}`);
    }

  }
  
  const fechaMovimientoDesdeAString = ()=>{
    let dia = fechaMovimientoDesde.getDate();
    let mes = fechaMovimientoDesde.getMonth() + 1;
    let year = fechaMovimientoDesde.getFullYear();

    if (mes<10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/0${mes}/${year}`);
    }
    else if (mes>=10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/${mes}/${year}`);
    }
    else if (mes<10 && dia>=10){
      setFechaMovimientoDesdeString(`${dia}/0${mes}/${year}`);
    }
    else{
      setFechaMovimientoDesdeString(`${dia}/${mes}/${year}`);
    }
  }

  const fechaMovimientoHastaAString = ()=>{
    let diaH = fechaMovimientoHasta.getDate();
    let mesH = fechaMovimientoHasta.getMonth() + 1;
    let yearH = fechaMovimientoHasta.getFullYear();

    if (mesH<10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/0${mesH}/${yearH}`);
    }
    else if (mesH>=10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/${mesH}/${yearH}`);
    }
    else if (mesH<10 && diaH>=10){
      setFechaMovimientoHastaString(`${diaH}/0${mesH}/${yearH}`);
    }
    else{
      setFechaMovimientoHastaString(`${diaH}/${mesH}/${yearH}`);
    }
  }

  //Pasa los metodos que pasan las fechas de Date a string para poder hacer la petición, 
  //luego llama al metodo que cambia el estado de refrescarBusqueda para que useEffect se active
  const fechasAString = ()=>{
    setCargando(true);
    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    botonBuscarRefrescarBusqueda();
  }

  const botonBuscarRefrescarBusqueda = () => {
    if(refrescarBusqueda===true){
      setRefrescarBusqueda(false);
    }
    else if (refrescarBusqueda===false){
      setRefrescarBusqueda(true);
    }
  }

  const handleChangeDniBusqueda = e => {
    setDniBusqueda(e.target.value);
  }

  const handleRadio = (dni, index) => {
    if(radioSelectIndex.current === index) {
      setDniPersonaParaPermiso(null); 
      radioSelectIndex.current = -1;
    } else {
      setDniPersonaParaPermiso(dni); 
      radioSelectIndex.current = index;
    }
  }


  return (
    <Dashboard title="Movimiento Totems Entrada" refresco={leer}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
    
        <FormControl fullWidth sx={{ display: "flex", flexDirection: 'row'}}>
          <Box sx={{ m: 1, width: '50%' }}>
            <InputLabel>Obra</InputLabel>
            <Select
              label="Obra"
              value={obra.Id_Zone.toString()}
              fullWidth
              disabled
            >
              <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
            </Select>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <TextField
              margin="none"
              fullWidth
              value={dniBusqueda}
              onChange={handleChangeDniBusqueda}
              id="dni"
              label="DNI"
              name="dni"
              autoComplete="dni"
            />
          </Box>
        </FormControl>
        <Stack sx={{ display: "flex", flexDirection: 'row', width: '100%' }}>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoDesde}
                  onChange={
                    (newValue) => {
                      setFechaMovimientoDesde(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoHasta}
                  onChange={(newValue) => {
                    setFechaMovimientoHasta(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Stack>
      
        <Button onClick={()=>{
          fechasAString();
          }}>Buscar</Button>

        <Button onClick={() => {handleOpenFormIngreso()}}>Generar Permiso</Button>

        <Tooltip title="Seleccione el personal al que desee realizar el permiso y luego presione Generar Permiso">
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>

        <Modal
          open={openModalIngreso}
          onClose={handleCloseFormIngreso}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IngresoPeatonal closeModal={handleCloseFormIngreso} dniPersonaDetalleVehiculo={dniPersonaParaPermiso} />
          </Box>
        </Modal>

        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 900 }}>

          <FormControl fullWidth>
            <RadioGroup>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.stickyHeader} width={0} padding={'none'}></TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Hora')}>
                  <TableSortLabel active={orderColumn == 'Hora'} direction={orderColumn != 'Hora' || orderDirection === "asc"? "asc" : "desc"}>
                  Hora
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                  <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                  Nombre
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell align="right" onClick={(e)=> handleSortRequest('Dni')}>
                  <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                  Dni
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                  <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                  Contratista
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Vigencia_Descripcion')}>
                  <TableSortLabel active={orderColumn == 'Vigencia_Descripcion'} direction={orderColumn != 'Vigencia_Descripcion' || orderDirection === "asc"? "asc" : "desc"}>
                  Estado
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Barrera')}>
                  <TableSortLabel active={orderColumn == 'Barrera'} direction={orderColumn != 'Barrera' || orderDirection === "asc"? "asc" : "desc"}>
                  Totem
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Motivo')}>
                  <TableSortLabel active={orderColumn == 'Motivo'} direction={orderColumn != 'Motivo' || orderDirection === "asc"? "asc" : "desc"}>
                  Requisito Faltante
                    </TableSortLabel>                    
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!cargando && (movimientos.map((movimiento, index) => (
                    <TableRow
                      key={index}
                      id={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      selected={index === radioSelectIndex.current ? true : false}
                      onClick={() => {
                        setRowSelectIndex(index);
                      }}
                    >
                      {movimiento.Vigencia_Descripcion === 'Apto' ?
                      <TableCell style={{ padding: '5px 10px' }} component="th" scope="row"></TableCell>
                      :
                      <TableCell style={{ padding: 0, zIndex: 1 }} component="th" scope="row">
                        <FormControlLabel sx={{ padding: 0, margin: 0, zIndex: 1}} value={movimiento.Dni} control={
                        <Radio size={'small'} value={movimiento.Dni} checked={dniPersonaParaPermiso === movimiento.Dni} onClick={ () => { handleRadio(movimiento.Dni, index) } }/>
                        } label={""}/>
                      </TableCell>
                      }
                      <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                        {movimiento.Hora}
                      </TableCell>
                      <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Nombre}</TableCell>
                      <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Dni}</TableCell>

                      {movimiento.Contratista === "- Otra (Pendiente de alta)" 
                      ? <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2 }}>{movimiento.Contratista}</p></TableCell>
                      : <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Contratista}</TableCell>
                      }
                      
                      
                      {movimiento.Vigencia_Descripcion === 'Apto' ?
                      <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                      :
                      <TableCell align="center" style={movimiento.Motivo !== '' ? estiloAmarillo : estiloRojo}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                      }

                      <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Barrera}</TableCell>
                      <TableCell style={{ padding: '5px 10px' }} align="right">
                        <Box style={{ maxWidth: '15vw' }}>
                          <Tooltip title={movimiento.Motivo}>
                            <Typography noWrap align="left">{movimiento.Motivo}</Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                    )))
                  }
                </TableBody>
              </Table>
              {cargando && <Box sx={{
                width: '100%',
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                margin: '1rem 0rem',
              }}>
                <CircularProgress/>
              </Box>}
            </RadioGroup>
          </FormControl>
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default MovimientoTotemsEntrada;