import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './pages/theme';
import { Provider as StoreProvider } from 'react-redux';
import { store } from './store';
import { CookiesProvider, Cookies } from "react-cookie";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, graphConfig  } from './authConfig';
import { fetchUserAD } from "./reducers/fetchWebService";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (tokenResponse != null) {
      const account = tokenResponse.account;

      console.log('tokenResponse', tokenResponse);
      console.log('account', account);

      const accessTokenRequest = {
        scopes: ["user.read"],
        account: account,
      };

      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          let accessToken = accessTokenResponse.accessToken;

          callMsGraph(accessToken).then((response) => 
            LoginUser(response.userPrincipalName, response.displayName)
          );
        })
        .catch(function (error) {
          console.log('msalInstance acquireTokenSilent ERROR', error);
        });
    }
  })
  .catch((error) => {
    console.log('msalInstance handleRedirectPromise ERROR', error);
  });

async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
      method: "GET",
      headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
      .then(response => response.json())
      .catch(error => console.log(error));
}  

function LoginUser(userPrincipalName, displayName) {
  const cookie = new Cookies();

  var id_User = cookie.get("Id_User");

  if (id_User == undefined || id_User == null) {
    const getUserAD = async () => {
      try {
        const resultado = await fetchUserAD(userPrincipalName, displayName);
        
        if (resultado.Id_User == 0) {
          window.location.href = '/login?errorLogin=1';
        }
        else {
          cookie.set('Id_User', resultado.Id_User);

          var datosHome = cookie.get("datosHome");
          
          if (datosHome == undefined || datosHome == null) {
            cookie.set('datosHome', [
              {
                userId: resultado.Id_User,
                abiertos: [], 
                columnas: [ 6,6,6,6,6,6,6,6,6 ], // ancho
                componentes: [ '1', '2', '3', '4', '5', '6', '7', '8', '9' ], // orden
              }
            ], {maxAge: 99999999});
          }

          window.location.href = '/';
        }

        console.log('fetchUserAD', resultado);
      } catch (error) {
        console.log('fetchUserAD ERROR', error);
      }
    };
  
    getUserAD();
  }
}  

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <CssBaseline />
            <App />
          </CookiesProvider>
        </ThemeProvider>
      </StoreProvider >
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
