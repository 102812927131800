import { Alert, Box, Grid, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import { fetchApi } from '../helpers/fetchApi';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import CachedIcon from '@mui/icons-material/Cached';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
}),
);

const PermisoDeIngresoVehicularTratados = () => {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const lengthPrevia = useRef(0);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();

  const estiloRojo = {
    color: "white",
    backgroundColor: "red",
    padding: '5px 10px',
  }

  const estiloVerde = {
    color: "white",
    backgroundColor: "green",
    padding: '5px 10px',
  }

  const estiloAzul = {
    color: "white",
    backgroundColor: "blue",
    padding: '5px 10px',
  }

  const estiloAmarillo = {
    color: "white",
    backgroundColor: "#FFA600",
    padding: '5px 10px',
  }

  const [permisos, setPermisos] = useState<any>([]);
  const [cargando, setCargando] = useState(true);

  const [orderColumn, setOrderColumn]= useState("Fecha");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(permisos, columnName, columnOrder);

    setPermisos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
  const HOST_URL = process.env.REACT_APP_HOST_URL;

  const urlGetVehicularPermisoTratado = SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetVehiculoPermisoTratado?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}`;
  const getVehicularPermisoTratado = async() => {
    return await fetchApi(`${urlGetVehicularPermisoTratado}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': HOST_URL,
      },
    });
  };

  const leer = async () => {
    const [payload, error] = await getVehicularPermisoTratado();
    if(!error){
      setCargando(true);
      lengthPrevia.current = payload.length
      setPermisos(payload);
      setCargando(false);
      setUltimoRefresco(new Date().toTimeString());

      if (rowSelectIndex != undefined) {
        let element = document.getElementById(rowSelectIndex.toString());
        element.scrollIntoView({behavior: "smooth", block: "end"});
      }
    }
  }

  const verificarNuevosDatos = async () => {
    // const [payload, error] = await getPersonalPermisoTratado();
    // if(!error){
    //   if(payload.length !== lengthPrevia.current){
    //     leer();
    //   }
    // }
  }

  useEffect(() => {
    leer();
    
    const interval = setInterval(() => {
      verificarNuevosDatos();
    }, 30000); // 30 seg
    return () => clearInterval(interval);
  }, [obra]);

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);
  

  return (
    <Dashboard title="Permisos de Ingreso Vehicular TRATADOS" refresco={leer}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
      <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 900 }}>
        <FormControl fullWidth>
        <InputLabel>Obra</InputLabel>
        <Select
          label="Obra"
          value={obra.Id_Zone.toString()}
          fullWidth
          disabled
        >
          <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
        </Select>
      </FormControl>
          <Button>Buscar</Button>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                  <TableCell onClick={(e)=> handleSortRequest('Tipo_Autorizacion')}>
                  <TableSortLabel active={orderColumn == 'Tipo_Autorizacion'} direction={orderColumn != 'Tipo_Autorizacion' || orderDirection === "asc"? "asc" : "desc"}>
                  Tipo
                    </TableSortLabel>                    
                    </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('LicensePlate')}>
                    <TableSortLabel active={orderColumn == 'LicensePlate'} direction={orderColumn != 'LicensePlate' || orderDirection === "asc"? "asc" : "desc"}>
                      Vehiculo
                    </TableSortLabel>
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                  <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                  Contratista
                    </TableSortLabel>                    
                    </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Supervisor')}>
                  <TableSortLabel active={orderColumn == 'Supervisor'} direction={orderColumn != 'Supervisor' || orderDirection === "asc"? "asc" : "desc"}>
                  Aprobador
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Requisito_Faltante')}>
                    <TableSortLabel active={orderColumn == 'Requisito_Faltante'} direction={orderColumn != 'Requisito_Faltante' || orderDirection === "asc"? "asc" : "desc"}>
                      Requisito Faltante
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                      Fecha
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell align="right">
                    Estado
                    </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (permisos.map((permiso: any, index) => (
                <TableRow
                  key={index}
                  id={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  // selected={index === rowSelectIndex ? true : false}
                  onClick={() => {
                    setRowSelectIndex(index);
                  }}
                >
                  <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                    {permiso.Tipo_Autorizacion}
                  </TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.LicensePlate}</TableCell>
                  
                  {permiso.Contratista === "- Pendiente de Carga" 
                  ? <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2 }}>{permiso.Contratista}</p></TableCell>
                  : <TableCell style={{ padding: '5px 10px' }} align="center">{permiso.Contratista}</TableCell>
                  }
                  
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.Supervisor}</TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="right">
                    <Box style={{ maxWidth: '15vw' }}>
                      <Tooltip title={permiso.Requisito_Faltante}>
                        <Typography noWrap align="left">{permiso.Requisito_Faltante}</Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.Fecha}</TableCell>
                  {permiso.Estado !== "APROBADO" // APROBADO (verde) - RECHAZADO (rojo) - NO CORRESPONDE (azul)
                    ? (permiso.Estado === "RECHAZADO") 
                    ? <TableCell align="center" style={estiloRojo}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                    : <TableCell align="center" style={estiloAzul}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                    : <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{permiso.Estado}</p></TableCell>
                  }
                </TableRow>
              )))}
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default PermisoDeIngresoVehicularTratados;