import { Box, Grid, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import { fetchApi } from '../helpers/fetchApi';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useCookies } from "react-cookie";
import useSound from 'use-sound';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
}),
);


const PermisoDeIngresoPendiente = () => {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const navigate = useNavigate();
  const [play] = useSound("./alarm.mp3");
  const [cookies] = useCookies();
  const lengthPrevia = useRef(0);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const [permisos, setPermisos] = useState<any>([]);
  const [cargando, setCargando] = useState(true);
  const [codigoPermisoString, setCodigoPermisoString] = useState<string>();
  const radioSelectIndex = useRef(-1);

  const [orderColumn, setOrderColumn]= useState("Fecha");
  const [orderDirection, setOrderDirection]= useState("asc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Fecha") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(permisos, columnName, columnOrder);

    setPermisos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
  const HOST_URL = process.env.REACT_APP_HOST_URL;

  const urlGetPersonalPermisoPendiente = SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/GetPersonalPermisoPendiente?latPosicion=${obra.Lat}&lonPosicion=${obra.Lon}`;
  const getPersonalPermisoPendiente = async() => {
    return await fetchApi(`${urlGetPersonalPermisoPendiente}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': HOST_URL,
      },
    });
  };

  const leer = async (diff?) => {

    const [payload, error] = await getPersonalPermisoPendiente();
    if(!error){
      setCargando(true);
      lengthPrevia.current = payload.length;
      setPermisos(payload);
      setUltimoRefresco(new Date().toTimeString());
      if(diff) {
        let rowNuevo = radioSelectIndex.current+diff;
        radioSelectIndex.current = rowNuevo;
      }
      setCargando(false);
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    const [payload, error] = await getPersonalPermisoPendiente();
    if(!error){
      if(payload.length !== lengthPrevia.current){
        let diff = payload.length - lengthPrevia.current;
        leer(diff);
      }
    }
  }

  const urlReenviarPermiso = SERVICE_URL + `webservices/SCAAppDataWebService_Web.asmx/ReenviarPermiso?codigoPermiso=${codigoPermisoString}`

  const reenviarPermiso = async() => {
    return await fetchApi(`${urlReenviarPermiso}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Host': HOST_URL,
      },
      // body: JSON.stringify({
      // codigoPermiso: codigoPermisoString
      // })
    });
  };

  const enviarReenviarPermiso = async () => {
    const [payload, error] = await reenviarPermiso();
    if(!error){
      //
    }
  }

  const handleRadio = (codigo, index) => {
    if(radioSelectIndex.current === index) {
      setCodigoPermisoString(null); 
      radioSelectIndex.current = -1;
    } else {
      setCodigoPermisoString(codigo); 
      radioSelectIndex.current = index;
    }
  }

  useEffect(() => {
      leer();

      const interval = setInterval(() => {
        verificarNuevosDatos();
      }, 30000); // 30 seg
      return () => clearInterval(interval);
  }, [obra]);
  
  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  return (
    <Dashboard title="Permisos de Ingreso PENDIENTES" refresco={leer}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 900 }}>
        <Button onClick={() => navigate('/ingresoPeatonal')}>Agregar</Button>
          <Button onClick={() => {enviarReenviarPermiso();}}>Reenviar permiso</Button>

          <FormControl fullWidth >
          <RadioGroup>
          
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                  <TableCell width={0} padding={'none'}></TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Fecha')}>
                    <TableSortLabel active={orderColumn == 'Fecha'} direction={orderColumn != 'Fecha' || orderDirection === "asc"? "asc" : "desc"}>
                    Fecha
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Personal')}>
                    <TableSortLabel active={orderColumn == 'Personal'} direction={orderColumn != 'Personal' || orderDirection === "asc"? "asc" : "desc"}>
                    Personal
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                    <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                    Contratista
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell>
                    Estado
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Supervisor')}>
                    <TableSortLabel active={orderColumn == 'Supervisor'} direction={orderColumn != 'Supervisor' || orderDirection === "asc"? "asc" : "desc"}>
                    Aprobador
                    </TableSortLabel>                    
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (permisos.map((permiso: any, index) => (
                <TableRow
                  key={index}
                  id={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  selected={index === radioSelectIndex.current ? true : false}
                  onClick={() => {
                    setRowSelectIndex(index);
                  }}
                >
                  <TableCell style={{ padding: 0, zIndex: 1 }} component="th" scope="row">
                    <FormControlLabel sx={{ padding: 0, margin: 0}} value={permiso.Code} control={
                    <Radio size={'small'} value={permiso.Code} checked={codigoPermisoString === permiso.Code} onClick={ () => {handleRadio(permiso.Code, index)} }/>
                    } label={""}/>
                  </TableCell>
                  <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                    {permiso.Fecha}
                  </TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.Personal}</TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.Contratista}</TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="right">-</TableCell>
                  <TableCell style={{ padding: '5px 10px' }} align="left">{permiso.Supervisor}</TableCell>
                </TableRow>
                )))
              }
              
            </TableBody>
          </Table>
          </RadioGroup>
          </FormControl>
        
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default PermisoDeIngresoPendiente;